import React from 'react'

// CountUp
import CountUp from 'react-countup';

// Img
// import globe from "../../img/bgimg/globe.webp"

// Icon Files
import Icons from '../../components/Icons';

// Css File
import "../../css/seonumbercard.css"

export default function Seonumber() {
  return (
    <div className="">
        {/* <div className="col-lg-4 col-md-5">
            <div data-aos="fade-right" data-aos-delay="400" className="partnership-card-img-bx">
                <img src={globe} alt="" />
            </div>
        </div> */}
        {/* <div className="col-lg-8 col-md-7 mt-3 mt-md-0"> */}
            <div className="common-heading-bx">
                <div className="common-heading ch-big">By the Numbers</div>
                <div className="common-sub-heading">Step into the world of trading excellence with Funded Firm – your gateway to global markets.</div>
            </div>
            <div className="row snc-gap mt-4">

                <div className="col-md-3 col-sm-6 col-6" data-aos="fade-up" data-aos-delay="400">
                    <div className="seo-number-card">
                        <Icons.WorldSvg />
                        <div className="snc-data-bx">
                        <div className="snc-number"><CountUp enableScrollSpy end={130}/>+</div>
                        <div className="snc-text">Countries</div>
                        </div>
                    </div>
                </div>
                
                <div className="col-md-3 col-sm-6 col-6" data-aos="fade-up" data-aos-delay="400">
                    <div className="seo-number-card">
                        <Icons.UserSvg />
                        <div className="snc-data-bx">
                        <div className="snc-number"><CountUp enableScrollSpy end={88}/>M+</div>
                        <div className="snc-text">Trader accounts</div>
                        </div>
                    </div>
                </div>

                <div className="col-md-3 col-sm-6 col-6" data-aos="fade-up" data-aos-delay="400">
                    <div className="seo-number-card">
                        <Icons.TransactionsSvg />
                        <div className="snc-data-bx">
                        <div className="snc-number"><CountUp enableScrollSpy end={30}/>M+</div>
                        <div className="snc-text">Monthly transactions</div>
                        </div>
                    </div>
                </div>

                <div className="col-md-3 col-sm-6 col-6" data-aos="fade-up" data-aos-delay="400">
                    <div className="seo-number-card">
                        <Icons.ResetSvg />
                        <div className="snc-data-bx">
                        <div className="snc-number">$<CountUp enableScrollSpy end={211}/>M</div>
                        <div className="snc-text">Monthly trade turnover</div>
                        </div>
                    </div>
                </div>

            </div>
        {/* </div> */}
    </div>
  )
}
