import React from 'react';
import {Link} from "react-router-dom";
import { Helmet } from "react-helmet-async";

// Design File
import StoreSection from '../../components/StoreSection';

export default function PageAbout() {

  return (
    <>
        <Helmet>
            <title>About Us | Funded Firm</title>
        </Helmet>

        <section className="relative">
            <div className="container-lg cl-custome">
                <div className="textlayout-main-bx">
                    <div className="breadcrumb-bx">
                        <Link className="breadcrumb-link" to="/">Home</Link>
                        <div className="breadcrumb-link breadcrumb-active">/ About Us</div>
                    </div>
                    <div data-aos="fade-up" className="tlm-heading">Pioneering Excellence: Redefining Trading <br/> Standards at Funded Firm</div>
                    <div data-aos="fade-up" data-aos-delay="400" className="tlm-sub-text">Empowering Traders Worldwide</div>
                    <div data-aos="fade-up" data-aos-delay="500" className="tlm-sub-text">Welcome to Funded Firm, the world's leading trading platform designed for traders of all levels. For people interested in CFD, or commodities markets, we strive to provide programs, services, and information that will make them more profitable, self-sufficient, and efficient</div>
                </div>
            </div>
        </section>

        <section className="pb-5">
            <div className="container-lg cl-custome">
                <div className="pt-lg-4 pt-4 pb-lg-5 mt-5">
                    <StoreSection />
                </div>
            </div>
        </section>

    </>
  );
};
