import React from 'react';
import { Link, NavLink } from "react-router-dom";

// Css Files
import '../../css/footer.css';

// Images Common Files
import * as Img from '../Img';

// Design Files
import Socialicon from '../Socialicon';

export default function Footer() {

    const footercontactinfo = [
        // {
        //     contactClassName: "col-md-12 col-sm-12 whatsapp-link",
        //     contactName: "whatsapp",
        //     contactImg: Img.whatsapp,
        //     contactData: "whatsapp",
        //     contactLink: "https://api.whatsapp.com/send?phone=+918081113111&text=Hello",
        // },
        {
            contactClassName: "col-md-12 col-sm-6",
            contactName: "Call Us",
            contactImg: Img.call,
            contactData: "+91 808 111 3111",
            contactLink: "tel:+918081113111",
        },
        {
            contactClassName: "col-md-12 col-sm-12",
            contactName: "Email Us",
            contactImg: Img.sms,
            contactData: "info@fundedfirm.com",
            contactLink: "mailto:info@fundedfirm.com",
        },
        // {
        //     contactClassName: "col-12",
        //     contactName: "Address",
        //     contactImg: Img.location,
        //     // contactData: "1st Floor  The Sotheby Building Rodney Bay Gros-Islet,SAINT Lucia P.O Box 838 Castries Saint Lucia.",
        //     contactData: "P.O Box 838 Castries, Saint Lucia.",
        //     contactLink: "/",
        // },
    ]

    return (
    <>        
        <footer className="main-footer pt-5 pb-1">
            <div className="container-lg cl-custome">
                <div className="row justify-content-lg-between justify-content-sm-around">

                    <div data-aos="fade-up" className="col-xl-3 col-lg-3 col-12 mb-lg-0 mb-4">

                        <div className="text-lg-start text-center">
                            <Link className="footer-logo" to="/">
                                <img src={Img.logo} alt=" " />
                            </Link>
                            <div className="footer-sub-heading">Engage in trading with Funded Firm – a single platform offering multiple opportunities.</div>
                        </div>
                        
                    </div>

                    <div data-aos="fade-up" className="col-xl-3 col-lg-3 col-md-4 col-sm-4 mb-sm-0 mb-4">
                        <div className="footer-heading">Markets</div>
                        <div className="footer-links-bx">
                            {/* <Link to="" className="footer-link">Forex</Link> */}
                            <Link to="" className="footer-link">Indices</Link>
                            <Link to="" className="footer-link">Stocks</Link>
                            <Link to="" className="footer-link">Cryptocurrency</Link>
                            <Link to="" className="footer-link">Commodities</Link>
                            <Link to="" className="footer-link">Gold and Silver</Link>
                        </div>
                    </div>

                    <div data-aos="fade-up" className="col-xl-3 col-lg-3 col-md-4 col-sm-4 mb-sm-0 mb-4">
                        <div className="footer-heading">Explore</div>
                        <div className="footer-links-bx">
                            <NavLink to="/" className="footer-link">Home</NavLink>
                            <Link to="/trading-rules" className="footer-link">Trading Rules</Link>
                            <Link to="" className="footer-link">About Us</Link>
                            <Link to="" className="footer-link">FAQs</Link>
                            <Link to="" className="footer-link">Privacy Policy</Link>
                            <Link to="" className="footer-link">Terms & Conditions</Link>
                            <Link to="" className="footer-link">Contact Us</Link>
                        </div>
                    </div>

                    <div data-aos="fade-up" className="col-xl-3 col-lg-3 col-md-4 col-sm-4">

                        <div className="footer-heading">Contact Info</div>
                        <div className="row">
                            { footercontactinfo.map((item, index) => ( 
                                <div className={item.contactClassName} key={index}>
                                    <Link className="footer-contact-info" to={item.contactLink}>
                                        
                                        <img src={item.contactImg} alt={`${item.contactName} Icon`} />
                                        
                                        {item.contactData}
                                        
                                    </Link>
                                </div>
                            ))}
                        </div>

                        <Socialicon className="social-icon justify-content-lg-start mt-3" />
                    </div>

                </div>

                <div className="footer-bottom">
                    <div className="copyright">© 2024 All Rights Reserved By Funded Firm</div>
                    <div className="mede-with">Made with
                        <span className="icons-box-made">
                            <i className="fa fa-heart-o" aria-hidden="true"></i>
                            <i className="fa fa-heart" aria-hidden="true"></i>
                        </span>
                        by: <a href="https://pmcommu.com/" target="_blank" rel="noreferrer">PM Communications</a>
                    </div>
                </div>

            </div>
        </footer> 

    </>
    );
}