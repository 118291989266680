import React from 'react'

// Icons File
import Icons from '../../components/Icons';

// Css File
import "../../css/PlatformWorks.css"

export default function PlatformWorks() {
    return (
        <div className="">

                <div className="common-heading-bx">
                    <div className="common-heading ch-big">A Platform that Works for You</div>
                    <div className="common-sub-heading">These features will help you reach your goals.</div>
                </div>

                <div className="row psc-gap mt-4">
                    <div className="col-lg-3 col-md-6 col-6">
                        <div data-aos="fade-Up" data-aos-delay="100" className="platform-section-card">
                            <Icons.VerificationSvg />
                            <div className="psc-data-bx">
                                <div className="psc-heading">RISK-FREE TRADES</div>
                                <div className="psc-text">Explore the platform and test strategies for free.</div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-3 col-md-6 col-6">
                        <div data-aos="fade-Up" data-aos-delay="200" className="platform-section-card">
                            <Icons.SettingSvg />
                            <div className="psc-data-bx">
                                <div className="psc-heading">DEMO ACCOUNT</div>
                                <div className="psc-text">10,000 in virtual funds to practice and learn.</div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-3 col-md-6 col-6">
                        <div data-aos="fade-Up" data-aos-delay="300" className="platform-section-card">
                            <Icons.BagSvg />
                            <div className="psc-data-bx">
                                <div className="psc-heading">MARKET</div>
                                <div className="psc-text">Customize the platform with unique tools to elevate your trading skills.</div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-3 col-md-6 col-6">
                        <div data-aos="fade-Up" data-aos-delay="400" className="platform-section-card">
                            <Icons.UserSvg />
                            <div className="psc-data-bx">
                                <div className="psc-heading">PERSONAL MANAGER</div>
                                <div className="psc-text">Get exclusive one-on-one guidance with a trading expert.</div>
                            </div>
                        </div>
                    </div>
                </div>
     
            {/* <div data-bs-target="#RegistrationModal" data-bs-toggle="modal" className="common-btn-item cbi-fill mt-5"><span>Registration</span></div> */}
        </div>
    )
}
