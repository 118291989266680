import React, { useState, useEffect }  from 'react';
import { Link, NavLink } from "react-router-dom";

// Css Files
import '../../css/header.css';

// Images Common File
import * as Img from '../Img';

// Icons
import Icons from '../Icons';

// Design File
import { useMenu } from './MenuContext';
import Sidemenu from './Sidemenu';

export default function Header() {

    // --- Header Js Start --- //
    const [headerSticky, setheaderSticky] = useState("");

    useEffect(() => {
      const handleScroll = () => {
        if (window.scrollY > 10) {
          setheaderSticky("active");
        } else {
          setheaderSticky("");
        }
      };
  
      // Attach the event listener when the component mounts
      window.addEventListener('scroll', handleScroll);
  
      // Remove the event listener when the component unmounts to avoid memory leaks
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }, []); // Empty dependency array ensures the effect runs only once when the component mounts
  
    // --- Header Js End --- //


    // --- ---
    const [isDropActive, setIsDropActive] = useState(false);

    const handleMouseEnterDrop = () => {
      setIsDropActive(true);
    };

    const handleMouseLeaveDrop = () => {
      setIsDropActive(false);
    };

    const handleDropClick = () => {
      setIsDropActive(false); // Remove active class when an item is clicked
    };
    // --- ---

    const { toggleMenu } = useMenu();
    // const { handleScreenChange } = useMenu();

    return (
      <>
        {/* <Topstrip /> */}
        
        <header className={`custom-header ${headerSticky}`}>
          <div className="ch-inner maxwidth1920">

            <div className="ch-left">
              {/* Website Logo Start */}
                <Link to="/" className="ch-logo">
                  <img className="logo-img" src={Img.logo2} alt="" />
                </Link>
              {/* Website Logo End */}
            </div>

            {/* Website Menu Start */}
            <div className="ch-menu-bx">

              <NavLink to="/" className="cmb-item">Home</NavLink>
              

              <div className={`cmb-drop-item ${isDropActive ? 'active' : ''}`} onMouseEnter={handleMouseEnterDrop} onMouseLeave={handleMouseLeaveDrop}>
                <div className="cmb-item">
                  Markets<Icons.DownSvg />
                  <div className="cmb-item-line"></div>
                </div>
                <div className={`cmb-drop-bx ${isDropActive ? 'active' : ''}`}>
                  <div className="cmb-drop-inner">
                    <div className="cdi-main" onClick={handleDropClick}>
                      {/* <Link to="" className="cdi-main-item">Forex</Link> */}
                      <Link to="" className="cdi-main-item">Indices</Link>
                      <Link to="" className="cdi-main-item">Stocks</Link>
                      <Link to="" className="cdi-main-item">Cryptocurrency</Link>
                      <Link to="" className="cdi-main-item">Commodities</Link>
                      <Link to="" className="cdi-main-item">Gold and Silver</Link>
                    </div>
                  </div>
                </div>
              </div>

              <Link to="/trading-rules" className="cmb-item">Trading Rules</Link>
              <Link to="" className="cmb-item">About Us</Link>
              {/* <Link to="" className="cmb-item">FAQs</Link> */}
              <Link to="" className="cmb-item">Contact Us</Link>

            </div>
            {/* Website Menu End */}


            <div className="ch-right">

              {/* Signup Btns Start */}
              {/* <div className="signinup-btn-bx"> 
                <div data-bs-target="#RegistrationModal" data-bs-toggle="modal" className="common-btn-item cbi-outline cbi-outline-white">Inquiry</div>
              </div> */}

<div className='dfgsdrhfgdrh'>
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" className="size-6">
    <path strokeLinecap="round" strokeLinejoin="round" d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z" />
  </svg>
  <input type='text' />
</div>
              {/* Signup Btns ENd */}

              <div className="menu-icon-bx" onClick={toggleMenu}>
                <span></span>
                <span></span>
                <span></span>
              </div>

            </div>
            
          </div>
        </header>

        <Sidemenu />
      </>
    );
}
  