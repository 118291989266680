import React from 'react';
import { Helmet } from "react-helmet-async";

// Img
import banner from "../../img/bgimg/homeimg.jpg"
// import banner2 from "../../img/bgimg/homeimg2.jpg"
import banner3 from "../../img/bgimg/cdagfserg.jpg"

// Video
// import video1 from "../../img/video/video(3).webm"
// import video2 from "../../img/video/video(3).mp4"


// Design File
import IndexSection2 from './IndexSection2';
// import Highlight from './Highlight';
import Seonumber from './Seonumber';
import TradingModes from './TradingModes';
import PlatformWorks from './PlatformWorks';
import BecomePro from './BecomePro';
import StoreSection from '../../components/StoreSection';
import Partnership from './Partnership';
import AccountStepsInfo from './AccountStepsInfo';
import Pricing from './Pricing';


// Custom hook to detect if the user is using any Apple device
// const useIsAppleDevice = () => {
//   const [isAppleDevice, setIsAppleDevice] = useState(false);

//   useEffect(() => {
//     const checkAppleDevice = () => {
//       const userAgent = navigator.userAgent || navigator.vendor || window.opera;
//       console.log("User Agent:", userAgent); // Log the user agent to verify it
//       if (/iPhone|iPad|iPod|MacIntel|Mac|Apple|Macintosh/i.test(userAgent)) {
//         setIsAppleDevice(true);
//         console.log("Apple device detected.");
//       } else {
//         console.log("Apple device not detected.");
//       }
//     };
//     checkAppleDevice();
//   }, []);

//   return isAppleDevice;
// };

export default function PageHome() {

  const questions = [
    {
      question: "What is Funded Firm?",
      answer: (
        <>
            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
        </>
      ),
    },

    {
      question: "How do I start trading with Funded Firm?",
      answer: (
        <>
            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
        </>
      ),
    },

    {
      question: "Which Financial instruments can I trade with Funded Firm?",
      answer: (
        <>
            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
        </>
      ),
    },

    {
      question: "How can I deposit and withdraw funds on Funded Firm?",
      answer: (
        <>
            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
        </>
      ),
    },


  ];
  
  // const videoRef = useRef(null);
  // const [showVideo, setShowVideo] = useState(false);

  // useEffect(() => {
  //     // Set a timer to show the video after 5 seconds
  //     const timer = setTimeout(() => {
  //         setShowVideo(true);
  //         if (videoRef.current) {
  //             videoRef.muted = true;
  //             videoRef.current.play();
  //             videoRef.current.play().catch((error) => {
  //               console.log('Autoplay failed:', error);
  //             });
  //         }
  //     }, 5000);

  //     // Cleanup the timer on component unmount
  //     return () => clearTimeout(timer);
  // }, []);

  // const isAppleDevice = useIsAppleDevice();

  return (
    <>
      <Helmet>
        <title>Funded Firm</title>
      </Helmet>

      <section className='demo-home-page-section'>
        <img src={banner} alt="" />
        <img src={banner3} alt="" />

          <IndexSection2 />
      </section>

 
      <section className="py-5 overflowhidden">
        <div className="container-lg cl-custome">
          <Partnership />
        </div>
      </section>

      <section className="py-5 bfgndtg overflowhidden">
        <div className="container-lg cl-custome">
          <AccountStepsInfo />
        </div>
      </section>

      <section className="py-5 overflowhidden">
        <div className="container-lg cl-custome">
        <TradingModes />
        </div>
      </section>

      <section className="py-5 bfgndtg overflowhidden">
        <div className="container-lg cl-custome">
        <Seonumber />
        </div>
      </section>

      <section className="py-5 overflowhidden">
        <div className="container-lg cl-custome">
        <BecomePro />
        </div>
      </section>

      <section className="py-5 bfgndtg overflowhidden">
        <div className="container-lg cl-custome">
        <Pricing />
        </div>
      </section>

      <section className="py-5 overflowhidden">
        <div className="container-lg cl-custome">
        <PlatformWorks />
        </div>
      </section>

      <section className="py-5 bfgndtg overflowhidden">
          <div className="container-lg cl-custome legal-content">
            <div className="common-heading-bx d-none d-md-block">
                <div className="common-heading ch-big">Frequently Asked Questions</div>
            </div>
            <div id="faqsaccordion" className="faqs-accordion  px-0 mt-2"> {/*  data-bs-parent="#faqsaccordion" */}
              {questions.map((item, index) => (
                <div data-aos="fade-up" className="acc-item" key={index}>
                    <div className="acc-heding collapsed" id={`heading${index}`} data-bs-toggle="collapse" data-bs-target={`#collapse${index}`} aria-expanded="true" aria-controls={`collapse${index}`}>
                        {item.question}
                        <span className="down-icon"></span>
                    </div>

                    <div id={`collapse${index}`} className="collapse" aria-labelledby={`heading${index}`}>
                        <div className="faqs-data">
                            {item.answer}
                        </div>
                    </div>
                </div>
              ))}
            </div>

            <div className='mt-3 d-flex justify-content-center' data-aos="fade-up">
            <div className="common-btn-item cbi-outline">View more</div>
            </div>
          </div>
      </section>

      <section className="py-5 overflowhidden">
        <div className="container-lg cl-custome">
          <StoreSection />
        </div>
      </section>


    </>
  );
};
