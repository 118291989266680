import React from 'react';

// Img
// import banner03 from "../../img/bgimg/mockup-iphone-blue.webp";
import Partnership1 from "../../img/icons/sf(3).png";
import Partnership2 from "../../img/icons/sf(2).png";
import Partnership3 from "../../img/icons/sf(1).png";


// Css
import "../../css/partnershipcard.css";

export default function Partnership() {
  return (
    <div className="row justify-content-center align-items-center">


            <div className="common-heading-bx">
                <div className="common-heading ch-big">Partnership</div>
                <div className="common-sub-heading ch-big">Create your personal partnership network and earn money every time your referral trades. Receive up to 60% Revenue Share.</div>
            </div>

            <div className="row rci-gap mt-4">
                
                <div className="col-md-4 col-12">
                    <div className="partnership-card-item">
                        <img className="pci-img" src={Partnership3} alt="" />
                        <div className="pci-data-bx">
                            <div className="pci-heading">Fully automated</div>
                            <div className="pci-text">Complete automation in all processes - we have provided all the conditions for creating your own partner network.</div>
                        </div>
                    </div>
                </div>
                
                <div className="col-md-4 col-12">
                    <div className="partnership-card-item">
                        <img className="pci-img" src={Partnership2} alt="" />
                        <div className="pci-data-bx">
                            <div className="pci-heading">Up to 10% Deposit Reward</div>
                            <div className="pci-text">Extra reward up to 10% of Net Deposit</div>
                        </div>
                    </div>
                </div>
                
                <div className="col-md-4 col-12">
                    <div className="partnership-card-item">
                        <img  className="pci-img" src={Partnership1} alt="" />
                        <div className="pci-data-bx">
                            <div className="pci-heading">Rebate service</div>
                            <div className="pci-text">Set the same percent rate for all or manage rebate for each referral.</div>
                        </div>
                    </div>
                </div>

            </div>


    </div>
  )
}
