import React from 'react';
import {Link} from "react-router-dom";
import { Helmet } from "react-helmet-async";

export default function PageTradingRules() {


    const questions = [
        {
            question: "1. It's All About Discipline And Consistency.",
            answer: (
            <>
                <p className="mt-1">No need to rush, you don't build a life in a day. There is plenty of time to pass the evaluation succesfully, being dynamic is crucial.</p>
            </>
            ),
        },

        {
            question: "2. Trading Behaviour and Rules",
            answer: (
            <>
                <p className="mt-1">We offer a Funded Firm Evaluation course which is based on two phases, student and practitioner, which you need to pass to become a Master trader aka funded trader. It's crucial that you have a dynamic trading system & risk management profile to pass the evaluation course. After you reach your profit target in Phase 2, your entire evaluation period will be manually reviewed by our Risk Team. This review process will be completed within two working days.</p>

                <p>In the event of a breach of any of the rules referred to in this section, all positions on the broker's platform shall be closed immediately, the Customer’s Account will be closed and the right to payout will be revoked.</p>

                <div className="main-heading">2.1. The Student Phase (Phase I)</div>
                <p>The student phase is to test your trading skills while defining yourself as a trader. During the student phase, you must achieve a profit target of 8% without breaching any other rule.‍ Once passed, it takes 24 hours to receive your Phase 2 account.</p>

                <div className="main-heading">2.2. The Practitioner Phase (Phase II)</div>
                <p>The practitioner phase is just to confirm the results and outcomes of the student phase. During the practitioner phase, you must achieve a profit target of 5% without breaching any other rule.</p>

                <div className="main-heading">2.3. 5% Maximum Daily Loss Limit</div>
                <p>The Maximum Daily Loss is the amount the trader is allowed to lose every day. For the purpose of this rule, the higher value between equity and balance will be used. This rule is set to 5% of the starting equity or balance every day. The rule states that the equity of the day, which is the result of the currently floating PnL (Profit and Loss) in sum with all closed positions of that day must not hit the Maximum Daily Loss Limit. The daily maximum loss resets at 00:00 CE(S)T/server every day.</p>

                <div className="main-heading">2.4. 10% Maximum Loss Limit</div>
                <p>The Maximum Loss Limit is the amount the equity or balance can't go below. This rule is set to 10% of the initial account size. For example, if the trader has a $100.000 account and the Maximum Loss Limit is 10%, the equity or balance can't go below $90.000 at any moment.</p>

                <div className="main-heading">2.5. Allowed to hold trades during news and over the weekend</div>

                <div className="main-heading">2.5.1. During the Evaluation (Student and Practitioner)</div>
                <p>You are allowed to hold trades over the weekend and trade during the news in the evaluation stage.</p>

                <div className="main-heading">2.5.2. During the Funded (Master only)</div>
                <p>You are allowed to hold trades during news and over the weekend. Profit from trades that are opened 2 minutes prior to and after a high impact news event on the affected currency will not be counted. Our system will automatically close the affected trades that are opened within the prohibited time window. High impact News event trading will result in the deduction of any profits made from trades executed within the restricted 4-minute window. We use Factory as our news calendar source. In the event any deductions result in the breach of the daily loss limit or maximum loss limit, the trader is responsible for the violation. Read more about news trading in our FAQ "Can I hold trades during news and over the weekend?"</p>

                <p><strong>Note:</strong> If there is a trade opened before the prohibited time window and closed in the prohibited time window, the trade will be counted.</p>

                <div className="main-heading">2.6. Trading Strategy</div>
                <p>Trade the way you want. Use an "EA", hold during news, hold trades over the weekend and trade lot sizes as big as the leverage allows for as long as it is not: Gap trading, high frequency trading, server spamming, latency arbitrage, toxic trading flow, hedging, long short arbitrage, reverse arbitrage, tick scalping, server execution, opposite account trading, which are all prohibited. Also, copy trading or account management by a third-party vendor is prohibited. Such activities with Funded Firm will result in account termination. Keep in mind that using a third-party Expert Advisor is allowed as long as it is a trade or risk manager. Using any other third-party Expert Advisor is not allowed. This will lead to a denial of the evaluation or payout and closure of the account.</p>

                <p>Remember! YOUR IDEAS, OUR RISK. To get funded and grow as a trader you should be able to trade and have the right set of skills. YOU CANNOT CHEAT YOUR WAY IN.</p>
            </>
            ),
        },

        {
            question: "3. Trading Instruments",
            answer: (
            <>
             
                <div className="main-heading mt-1">3.1. Tradable instrument and commission</div>
                <p>You can trade Crypto, Indices, Metals & Energies with RAW spreads and a 2$ commission per standard lot round turn. Crypto, Indices and Oil are commission free.</p>

                <div className="main-heading">3.2. Leverage</div>
                <p>The leverage that will be applied on all accounts in the 2-Step model is:</p>

                <ul>
                    <li>Metals: 1:30</li>
                    <li>Indices: 1:20</li>
                    <li>Energies: 1:10</li>
                    <li>Crypto: 1:2</li>
                </ul>
            </>
            ),
        },

        {
            question: "4. Account Credentials",
            answer: (
            <>
                <p className="mt-1">Once the evaluation program is purchased the trader will receive the login credentials immediately, changing the account credentials is prohibited under any circumstances, if a trader changes the credentials the account will be suspended. Funded Firm is constantly looking for skillful traders to grow with over the long run, it is crucial to be the trader, YOUR IDEAS OUR RISK. A trader cannot use signal bots, trade management services, or trade copiers. Funded Firm’s software is constantly monitoring the activities of traders, and a breach of any rule will result in account suspension.</p>
            </>
            ),
        },

        {
            question: "5. Payouts & Profit Split",
            answer: (
            <>
                <p className="mt-1">If the trader proves to be skillful, then a competitive scaling plan is the least we can offer to show appreciation.</p>

                <div className="main-heading">5.1. The Payday: Tuesday Payout</div>
                <p>A trader is eligible to request a payout every Tuesday. An 80% profit split will be applied.</p>

                <ul>
                    <li>Master accounts started on Monday can request on Tuesday (the next day).</li>
                    <li>Master accounts started on Tuesday can request on Tuesday (same day).</li>
                    <li>Master accounts started on Wednesday can request on Tuesday (after 4 trading days).</li>
                    <li>Master accounts started on Thursday can request on Tuesday (after 3 trading days).</li>
                    <li>Master accounts started on Friday can request on Tuesday (after 2 trading days).</li>
                </ul>

                <p>This means that the payout schedule can range from the same day to 4 trading days.</p>              

                <div className="main-heading">5.2 On-Demand Payouts + 90% profit split</div>
                <p>Once a trader earns a Hot Seat in our firm, the trader receives On-Demand payouts with a 90% profit split applied. More can be read in our FAQ "What is the Hot Seat program?"</p>

                <p><strong>Note:</strong> The minimum amount for a payout is 1% of the initial balance including our split.</p>

            </>
            ),
        },

        {
            question: "6. Scaling Up",
            answer: (
            <>
                <p className="mt-1">If the trader proves to be skillful, then a competitive scaling plan is the least we can offer to show appreciation.</p>

                <div className="main-heading">6.1 Scaling Plan</div>

                <div className="main-heading">6.1.1. Launchpad (Level 1)</div>
                <p>You'll begin as a Novice Trader. Successfully completing this stage with four payouts and 10% total profit will mark your entry into our program with a 20% scale-up.</p>
                <ul>
                    <li>Maximum drawdown increased by 1%</li>
                </ul>

                <div className="main-heading">6.1.2. Ascender (Level 2)</div>
                <p>As an Intermediate Trader, which you achieve after eight successful payouts with a total profit of 20%, you'll receive a 30% increase in your initial capital.</p>
                <ul>
                    <li>Maximum drawdown increased by 1%, totaling a 2% increase.</li>
                    <li>Daily drawdown increased by 1%</li>
                </ul>

                <div className="main-heading">6.1.3. Trailblazer (Level 3)</div>
                <p>Upon reaching Advanced Trader status after twelve payouts with a total profit of 30%, your capital allocation will increase by 40%. This recognizes your growing expertise and trading acumen.</p>
                <ul>
                    <li>Maximum drawdown increased by 1%, totaling a 3% increase.</li>
                </ul>

                <div className="main-heading">6.1.4. Hot Seat</div>
                <p>After sixteen payouts with a total profit of 40%, you'll ascend to the pinnacle as an Elite Trader. This exclusive status offers an array of premium benefits: double the initial balance.</p>
                <p>Personal support & access to Advance Trading Tools - Research</p>
                <ul>
                    <li>Access to on-demand payouts for financial flexibility.</li>
                    <li>A remarkable 90% profit split ensures your hard-earned profits stay with you.</li>
                    <li>Potential access to up to $2 million in capital to supercharge your trading.</li>
                    <li>Customized trading conditions tailored precisely to your unique trading strategy.</li>
                    <li>Maximum drawdown increased by 1%, totaling a 4% increase.</li>
                    <li>Daily drawdown increased by 1%, totaling a 2% increase.</li>
                </ul>
                
                <div className="main-heading">Monthly bonuses</div>
                <p>We provide monthly bonuses once you reach the Hot Seat based on the initial account size.</p>

                <ul>
                    <li>$100 for a 5K account</li>
                    <li>$200 for a 10K account</li>
                    <li>$300 for a 25K account</li>
                    <li>$400 for a 50K account</li>
                    <li>$500 for a 100K account</li>
                </ul>

                <p><strong>Note:</strong> The scale-up is always based on the initial account size. Also, if an account is merged, it will be based on the initial account, not the merged account size.</p>

                <p>Risk-Based Capital Allocation: Allocate capital based on a trader's risk-adjusted performance. Traders who demonstrate better risk-adjusted returns receive more capital, regardless of their trading history.</p>
            </>
            ),
        },

        {
            question: "7. Merging Accounts",
            answer: (
            <>
                <p>The first trading account you will manage will be the size you signed up for, this could be $25.000, $50.000 or $100.000. You can trade up to $300.000 combined.</p>

                <div className="main-heading">7.1. Each Evaluation and trading account has to be traded individually</div>
                <p>You must trade each account individually. You cannot copy trades (whether EA or manual) from one trading account to another, from a master account to another master account, from a master account to an evaluation account, or from one evaluation to another in order to pass them simultaneously with the same trades and trade ideas.</p>

                <div className="main-heading">7.2 Merging Master Accounts</div>
                <p>The Master/Funded accounts can be combined into one account or you can keep it separately after passing the evaluation stage, it's up to your own preference. The Master accounts have to be from the same evaluation model to merge:</p>

                <ul>
                    <li>2 Step Master accounts can only be merged with 2 Step Master accounts (also with the previously known Standard Model).</li>
                </ul>

                <p>To merge the Master/Funded accounts into one account, you must send <Link to="mailto:info@fundedfirm.com">info@fundedfirm.com</Link> an email or open a ticket through the helpdesk so the team can merge the account.</p>
            </>
            ),
        },

        {
            question: "8. Refunds",
            answer: (
            <>
                <p className="mt-1">All traders who pass the Evaluation will receive a refund of their fees along with their 4th payout.</p>
            </>
            ),
        },

        {
            question: "9. Inactivity",
            answer: (
            <>
                <p className="mt-1">Any trading account inactive for 30 days will be automatically suspended.</p>
            </>
            ),
        },

        {
            question: "10. IP Address",
            answer: (
            <>
                <div className="main-heading mt-1">10.1 IP Address matching Standard Evaluation Stage and Master Stage</div>
                <p>The region of your IP address(es) used to purchase the Evaluation, log in to <Link>www.fundedfirm.com</Link>, during the evaluation stage, the Master trading account should match and remain consistent. If our Risk Team detects a change in region, they may reach out to you to confirm and request proof.</p>

                <div className="main-heading">10.2 IP Address Master Account</div>
                <p>The region of your IP address(es) used to log into the Master account must remain consistent. If our Risk Team detects a change in region, they may reach out to you to confirm and request proof, such as a plane ticket, passport stamp, or live video from the location. This measure is in place to protect you from third-party vendors. If you anticipate traveling, kindly notify our support team beforehand to keep us informed and prevent interference from our end.</p>
            </>
            ),
        },
    ];


  return (
    <>
        <Helmet>
            <title>Trading Rules | Funded Firm</title>
        </Helmet>

        <section className="relative">
            <div className="container-lg cl-custome">
                <div className="textlayout-main-bx">
                    <div className="breadcrumb-bx">
                        <Link className="breadcrumb-link" to="/">Home</Link>
                        <div className="breadcrumb-link breadcrumb-active">/ Trading Rules</div>
                    </div>
                    <div data-aos="fade-up" className="tlm-heading">Trading Rules</div>
                    <div data-aos="fade-up" data-aos-delay="500" className="tlm-sub-text">Funded Firm is built by traders for traders, the evaluation process, trading condition, objectives and trading rules are all designed to offer the greatest experience for our traders. Your Success is ours as well. It's simple, be a consistent and disciplined trader.</div>
                </div>
            </div>
        </section>

        <section className="pb-5">
          <div className="container-lg cl-custome">
            <div id="faqsaccordion" className="faqs-accordion  px-0 mt-4"> {/*  data-bs-parent="#faqsaccordion" */}
              {questions.map((item, index) => (
                <div className="acc-item" key={index}>
                    <div className="acc-heding collapsed" id={`heading${index}`} data-bs-toggle="collapse" data-bs-target={`#collapse${index}`} aria-expanded="true" aria-controls={`collapse${index}`}>
                        {item.question}
                        <span className="down-icon"></span>
                    </div>

                    <div id={`collapse${index}`} className="collapse" aria-labelledby={`heading${index}`}>
                        <div className="faqs-data">
                            {item.answer}
                        </div>
                    </div>
                </div>
              ))}
            </div>
          </div>
      </section>

    </>
  );
};
