import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

// Context File
import Layout from "./Layout";
import { MenuProvider } from '../components/Header/MenuContext';

//Header section
import ScrollToTop from "./ScrollToTop";
import Loader from "./Loader";
import Header from "../components/Header/Header";

// Pages
import PageHome from "../containers/PageHome/PageHome";
import PageAbout from "../containers/PageAbout/PageAbout";
import PageTradingRules from "../containers/PageTradingRules/PageTradingRules";
import PageContact from "../containers/PageContact/PageContact";

// Lagle Pages
import PageFAQs from "../containers/PagreLagle/PageFAQs";
import PagePrivacy from "../containers/PagreLagle/PagePrivacy";
import PageTermsAndConditions from "../containers/PagreLagle/PageTermsAndConditions";

// Footer section
import Footer from "../components/Footer/Footer";
import RegistrationModal from "../components/RegistrationModal"
import FlashMessage from "../components/FlashMessage";

export const pages = [

  // Main Pages Links
  { path: "/", component: PageHome },
  { path: "/about", component: PageAbout },
  { path: "/trading-rules", component: PageTradingRules },
  { path: "/contact", component: PageContact },
  { path: "/faqs", component: PageFAQs },
  { path: "/privacy-policy", component: PagePrivacy },
  { path: "/terms-and-conditions", component: PageTermsAndConditions },

];

const MyRoutes = () => {

  return (
    <>
      <Layout>
        <ScrollToTop />
        <Loader />
        <Header  />
        <main className="main-wrapper">
          <Routes>
            {pages.map(({ component: Component, path }, index) => {
              return <Route key={index} element={<Component />} path={path} />;
            })}
          </Routes>
        </main>
        <Footer />
        <RegistrationModal />
        <FlashMessage />
      </Layout>
    </>
  );
};

function MainRoutes() {
  return (
    <Router>
      <MenuProvider>
        <MyRoutes />
      </MenuProvider>
    </Router>
  );
}

export default MainRoutes;