import React, { useState, useRef } from 'react';
import 'react-phone-number-input/style.css';
import * as Img from './Img';
import axios from 'axios';
import API_HOST from '../config/config';
import PhoneInput, { isValidPhoneNumber, parsePhoneNumber } from 'react-phone-number-input'
import FlashMessage from '../components/FlashMessage';

const EMAIL_REGEX = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

export default function RegistrationModal() {
    const errRef = useRef();
    const [errMsg, setErrMsg] = useState('');
    const [contactFormButton, setContactFormButton] = useState(true);

    const [phoneNumber, setPhoneNumber] = useState('');
    const handleOnChange = (value) => {
        setPhoneNumber(value);
    };

    const nameRef = useRef();
    const lastNameRef = useRef();
    const emailRef = useRef();
    const phoneRef = useRef();
    
    

    const [name, setName] = useState('');
    const [validName, setValidName] = useState(true);
    const [nameMsg, setNameMsg] = useState('');

    const [lastName, setLastName] = useState('');
    const [validLastName, setValidLastName] = useState(true);
    const [lastNameMsg, setLastNameMsg] = useState('');

    const [email, setEmail] = useState('');
    const [validEmail, setValidEmail] = useState(true);
    const [emailMsg, setEmailMsg] = useState('');

    const [phoneValue, setPhoneValue] = useState('');
    const [phoneMsg, setPhoneMsg] = useState('');
    const [isValid, setIsValid] = useState(true);

    const [showFlash, setShowFlash] = useState(false);
    const [flashMsg, setFlashMsg ] = useState('');
    const [flashType, setFlashType ] = useState('');

    // --- ---
    const handleInputChange = (val) => {
        setPhoneValue(val || '');
        setIsValid((!!val && isValidPhoneNumber((val || ''))));
        if(val === '')
        {
            setPhoneMsg('Phone Number is required');
        }
        else if((!!val && isValidPhoneNumber((val || ''))) === false)
        {
            setPhoneMsg('Please enter a valid number.');
        }
        else if(val !== '' && ((!!val && isValidPhoneNumber((val || ''))) === true))
        {
            setPhoneMsg('');
        }    
    };
    
    const phoneNumberCheck = () => {
        setIsValid((!!phoneValue && isValidPhoneNumber(phoneValue || '')));
        if(phoneValue === '')
        {
            setPhoneMsg('Phone Number is required');
        }
        else if((!!phoneValue && isValidPhoneNumber((phoneValue || ''))) === false)
        {
            setPhoneMsg('Please enter a valid number.');
        }
        else if(phoneValue !== '' && ((!!phoneValue && isValidPhoneNumber((phoneValue || ''))) === true))
        {
            setPhoneMsg('');
        }
        else
        {
            setPhoneMsg('');
        }
        return (!!phoneValue && isValidPhoneNumber((phoneValue || '')));
    }

    function checkName(value)
    {
        setName(value);
        if(value !== ""){ setValidName(true); }else{ setValidName(false); }
    }

    function checkLastName(value)
    {
        setLastName(value);
        if(value !== ""){ setValidLastName(true); }else{ setValidLastName(false); }
    }

    function checkEmail(value)
    {
        setEmail(value);
        if(value !== ""){
            const email_result = EMAIL_REGEX.test(value);
            setValidEmail(email_result);
        }else{ 
            setValidEmail(false); 
        }
    }
    
    function fundFormSubmitCheck () {
        var checkVal = [];
        
        if(name !== ""){ checkVal = [...checkVal,true]; setValidName(true); }else{ checkVal = [...checkVal,false]; setValidName(false); }
        if(lastName !== ""){ checkVal = [...checkVal,true]; setValidLastName(true); }else{ checkVal = [...checkVal,false]; setValidLastName(false); }
        if(phoneValue !== ""){
            const phone_result = phoneNumberCheck();
            setIsValid(phone_result);
            checkVal = [...checkVal,phone_result];
        }else{ 
            setIsValid(false); 
            checkVal = [...checkVal,false];
        }
    
        if(email !== ""){
            const email_result = EMAIL_REGEX.test(email);
            setValidEmail(email_result);
            checkVal = [...checkVal,email_result];
        }else{ 
            setValidEmail(false);
            checkVal = [...checkVal,false];
        }
           
        if(checkVal.includes(false)) { return false; } else { return true; }
    }
    
    const handleFundFormSubmit = async (e) => {
        setShowFlash(false);
        e.preventDefault();        
        if(!fundFormSubmitCheck())
        {
            setErrMsg("");
            return;
        }
        else{
            setErrMsg('');
            setValidName(true);
            setValidLastName(true);
            setIsValid(true);
            setValidEmail(true);
            
        }
    
        try {
            setContactFormButton(false);
            var phoneValueGet = '';
            if((isValidPhoneNumber((phoneValue || ''))) === true)
            {
                const phoneNumber = parsePhoneNumber(phoneValue);
                if (phoneNumber) {
                    phoneValueGet = `${phoneNumber.number}`;
                }
            }
            const response = await axios.post(API_HOST+'/xhr/save-registration.php', { 
                name : `${name}`,
                last_name : `${lastName}`,
                phone : `${phoneValueGet}`,
                email : `${email}`,
                
            },{headers: {
                "Content-Type": "multipart/form-data",
                withCredentials: true
            }},)
            .then(response => {
                if(response.data.error === '0' || response.data.error === 0)
                {
                    setErrMsg('');
                    setName('');
                    setValidName(true);
                    setNameMsg('');
                    setLastName('');
                    setValidLastName(true);
                    setLastNameMsg('');
                    setEmail('');
                    setValidEmail(true);
                    setEmailMsg('');
                    setPhoneValue('');
                    setIsValid(true);
                    setPhoneMsg('');
                    setContactFormButton(true);
                      //flash message
                    setFlashMsg(response.data.msg)
                    setShowFlash(true);
                    setFlashType('success');
                    // Toast.fire({
                    //   icon: 'success',
                    //   title: `${response.data.msg}`
                    // });
                    setTimeout(() => {
                      window.location.reload();
                    }, 3200);
                   
                    //$('#GetFundedModal').modal('hide');
                }  else {
                    setFlashMsg('No Server Response')
                    setShowFlash(true);
                    setFlashType('error');
                }                 
            })
            .catch(error => {
                if(!error?.response)
                {
                    // Toast.fire({
                    //     icon: 'error',
                    //     title: "No Server Response."
                    // });
                    setFlashMsg('No Server Response')
                    setShowFlash(true);
                    setFlashType('error');
                }
                else if(error.response.data.error === 1)
                {
                    if(typeof error.response.data.data === 'object' && error.response.data.data !== null && Object.keys(error.response.data.data).length > 0)
                    {   
                        if('name' in error.response.data.data)
                        {
                            setNameMsg(error.response.data.data.name.toString());
                            setValidName(false);
                        }
    
                        if('lastName' in error.response.data.data)
                        {
                            setLastNameMsg(error.response.data.data.lastName.toString());
                            setValidLastName(false);
                        }
    
                        if('phone' in error.response.data.data)
                        {
                            setPhoneMsg(error.response.data.data.phone.toString());
                            setIsValid(false);
                        }
    
                        if('email' in error.response.data.data)
                        {
                            setEmailMsg(error.response.data.data.email.toString());
                            setValidEmail(false);
                        }
    
                    }  
                    // Toast.fire({
                    //     icon: 'error',
                    //     title: "contact us not send."
                    // });
                    setFlashMsg('No Server Response')
                    setFlashType('error');  
                    setShowFlash(true);                  
                }
                else if(error.response?.status === 409 )
                {
                    // Toast.fire({
                    //     icon: 'error',
                    //     title: "contact us not send."
                    // });
                    setFlashMsg('No Server Response')
                    setFlashType('error');  
                    setShowFlash(true);    
                }
                else{
                    // Toast.fire({
                    //     icon: 'error',
                    //     title: "contact us not send."
                    // });
                    setFlashMsg('No Server Response')
                    setFlashType('error');  
                    setShowFlash(true);    
                }
                setContactFormButton(true);
            });
            setContactFormButton(true);
        } catch (err) {
            setContactFormButton(true);
            console.error('Error fetching data catch:', err);
        }
    }
    // --- ---
    return (
        <>
            <div className="modal fade zoom" id="RegistrationModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content custom-content cc-style-2">
                        <div className="custom-modal-header">
                            <div className="cmh-lable">Inquiry</div>

                            <span className="close-icon white" data-bs-dismiss="modal" aria-label="Close">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                    <path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="32" d="M368 368L144 144M368 144L144 368"></path>
                                </svg>
                            </span>
                        </div>
                        <div className="mt-2">
                            <form className="row px-3 pb-4" onSubmit={handleFundFormSubmit}>
                                <div className="col-sm-6">
                                    <div className={`signinup-group ${name === '' && !validName ? "error-group" : ""} `}>
                                        <label className="group__label">First Name</label>
                                        <input type="text" ref={nameRef} placeholder="First Name" onChange={(e) => checkName(e.target.value)} aria-invalid={validName ? "false" : "true"} aria-describedby="name_error" />
                                  <span id="name_error" className={`form-error-msg mb-1 ${name === '' && !validName ? "" : "d-none"} `}>{nameMsg !== '' ? nameMsg : 'Please enter firstname'}</span>
                                    </div>
                                </div>

                                <div className="col-sm-6">
                                    <div className={`signinup-group ${lastName ==='' && !validLastName ? "error-group" : ""} `}>
                                        <label className="group__label">Last Name</label>
                                        <input type="text" placeholder="Last Name" ref={lastNameRef} onChange={(e) => checkLastName(e.target.value)} aria-invalid={validLastName ? "false" : "true"} aria-describedby="last_name_error"/>
                                        <span id="last_name_error" className={`form-error-msg mb-1 ${lastName === '' && !validLastName ? "" : "d-none"} `}>{lastNameMsg !== '' ? lastNameMsg : 'Please enter lastname'}</span>
                                    </div>
                                </div>

                                <div className="col-md-6">
                                    <div className={`signinup-group ${email === '' && !validEmail ? "error-group" : ""} `}>
                                        <label className="group__label">Email</label>
                                        <input type="email" id="email" ref={emailRef} autoComplete="off" onChange={(e) => checkEmail(e.target.value)} aria-invalid={validEmail ? "false" : "true"} aria-describedby="email_error" placeholder="Email ID" value={email} />
                                        <span id="email_error" className={`form-error-msg mb-1 ${!validEmail ? "" : "d-none"} `}>{emailMsg !== '' ? emailMsg : 'Please enter valid email'}</span>
                                    </div>
                                </div>

                                <div className="col-md-6">
                                    <div className={`signinup-group sg-country-code ${phoneValue === '' && !isValid ? "error-group" : ""} `}>
                                        <div className="group__label">Phone No.</div>
                                        <PhoneInput 
                                        // international 
                                        countryCallingCodeEditable={false} defaultCountry="IN"  placeholder="Phone number" id="phone" ref={phoneRef} value={phoneValue} onChange={handleInputChange}/>
                                        {/* <input type="number" placeholder="Enter your Phone no. here"/>
                                        <span className="group_left_icon">+1</span> */}
                                        <span id="phone_error" className={`form-error-msg mb-1 ${!isValid ? "" : "d-none"} `}>{phoneMsg !== '' ? phoneMsg : 'Please enter valid number'}</span>
                                    </div>                            
                                </div>

                                <div className="col-12">
                                    <button className={`widthfull common-btn-item cbi-fill mt-2 ${contactFormButton ? '' : 'd-none'}`} >Submit</button>
                                    <button type="button" className={`widthfull common-btn-item cbi-fill mt-2 lsb-loader-btn ${contactFormButton ? 'd-none' : ''} `}>
                                        <img className="invert" src={Img.loading} alt="Please wait" />Please wait ....
                                    </button>
                                </div>
                                <FlashMessage show={showFlash} message={flashMsg} type={flashType}></FlashMessage>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}
