import React from 'react'
// import { TypeAnimation } from 'react-type-animation';

// Css File
import "../../css/IndexSection.css"

export default function IndexSection2() {
  return (
    <div className="white-section textlayout-main-bx">
        <div className="tlm-heading">
          Our Capital <br/> Your Profit
        </div>
        {/* <div data-aos="fade-up" className="tlm-heading">They're <span className="tlm-h-color">Fast</span> <br/> We're Faster</div> */}
        <div className="tlm-sub-text">A trading platform that supports <br className='d-block d-sm-none'/> your financial goals.</div>
        <div className="tlm-btns">
            <div className="common-btn-item cbi-fill cbi-fill-white"><span>SIGN IN</span></div>
            <div data-bs-target="#RegistrationModal" data-bs-toggle="modal" className="common-btn-item cbi-outline cbi-outline-white"><span>GET Funded</span></div>
        </div>
    </div>
  )
}
