import React, { useState, useEffect, useRef } from 'react';
import {Link} from "react-router-dom";
import { Helmet } from "react-helmet-async";

// Phone Number Contry Code
import 'react-phone-number-input/style.css';
import axios from 'axios';
import API_HOST from '../../config/config';
import PhoneInput, { isValidPhoneNumber, parsePhoneNumber } from 'react-phone-number-input'

import * as Img from '../../components/Img';
import FlashMessage from '../../components/FlashMessage';

// Design Files
import Socialicon from '../../components/Socialicon';

// Images Common File
import phone from '../../img/icons/contact/phone.svg'
import at from '../../img/icons/contact/at.svg'
import location from '../../img/icons/contact/location.svg'
const EMAIL_REGEX = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

export default function PageContact() {
    const errRef = useRef();
    const [errMsg, setErrMsg] = useState('');
    const [contactFormButton, setContactFormButton] = useState(true);

    const [phoneNumber, setPhoneNumber] = useState('');
    const handleOnChange = (value) => {
        // Update state or perform other actions when the phone number changes
        setPhoneNumber(value);
    };

    const nameRef = useRef();
    const lastNameRef = useRef();
    const emailRef = useRef();
    const phoneRef = useRef();
    const messageRef = useRef();

    const [name, setName] = useState('');
    const [validName, setValidName] = useState(true);
    const [nameMsg, setNameMsg] = useState('');

    const [lastName, setLastName] = useState('');
    const [validLastName, setValidLastName] = useState(true);
    const [lastNameMsg, setLastNameMsg] = useState('');

    const [email, setEmail] = useState('');
    const [validEmail, setValidEmail] = useState(true);
    const [emailMsg, setEmailMsg] = useState('');

    const [phoneValue, setPhoneValue] = useState('');
    const [phoneMsg, setPhoneMsg] = useState('');
    const [isValid, setIsValid] = useState(true);

    const [message, setMessage] = useState('');
    const [messageMsg, setMessageMsg] = useState('');
    const [validMessage, setValidMessage] = useState(true);
    const [showFlash, setShowFlash] = useState(false);
    const [flashMsg, setFlashMsg ] = useState('');
    const [flashType, setFlashType ] = useState('');

    const handleInputChange = (val) => {
        setPhoneValue(val || '');
        setIsValid((!!val && isValidPhoneNumber((val || ''))));
        if(val == '')
        {
            setPhoneMsg('Phone Number is required');
        }
        else if((!!val && isValidPhoneNumber((val || ''))) == false)
        {
            setPhoneMsg('Please enter a valid number.');
        }
        else if(val != '' && ((!!val && isValidPhoneNumber((val || ''))) == true))
        {
            setPhoneMsg('');
        }    
    };
    
    const phoneNumberCheck = () => {
        setIsValid((!!phoneValue && isValidPhoneNumber(phoneValue || '')));
        if(phoneValue == '')
        {
            setPhoneMsg('Phone Number is required');
        }
        else if((!!phoneValue && isValidPhoneNumber((phoneValue || ''))) == false)
        {
            setPhoneMsg('Please enter a valid number.');
        }
        else if(phoneValue != '' && ((!!phoneValue && isValidPhoneNumber((phoneValue || ''))) == true))
        {
            setPhoneMsg('');
        }
        else
        {
            setPhoneMsg('');
        }
        return (!!phoneValue && isValidPhoneNumber((phoneValue || '')));
    }

    function checkName(value)
    {
        setName(value);
        if(value != ""){ setValidName(true); }else{ setValidName(false); }
    }

    function checkLastName(value)
    {
        setLastName(value);
        if(value != ""){ setValidLastName(true); }else{ setValidLastName(false); }
    }

    function checkEmail(value)
    {
        setEmail(value);
        if(value != ""){
            const email_result = EMAIL_REGEX.test(value);
            setValidEmail(email_result);
        }else{ 
            setValidEmail(false); 
        }
    }

    function checkMessage(value)
    {
        setMessage(value);
        if(value != ""){ setValidMessage(true); }else{ setValidMessage(false); }
    }

    function fundFormSubmitCheck () {
        
        var checkVal = [];
        
        if(name != ""){ checkVal = [...checkVal,true]; setValidName(true); }else{ checkVal = [...checkVal,false]; setValidName(false); }
        if(lastName != ""){ checkVal = [...checkVal,true]; setValidLastName(true); }else{ checkVal = [...checkVal,false]; setValidLastName(false); }
        if(phoneValue != ""){
            const phone_result = phoneNumberCheck();
            setIsValid(phone_result);
            checkVal = [...checkVal,phone_result];
        }else{ 
            setIsValid(false); 
            checkVal = [...checkVal,false];
        }
    
        if(email != ""){
            const email_result = EMAIL_REGEX.test(email);
            setValidEmail(email_result);
            checkVal = [...checkVal,email_result];
        }else{ 
            setValidEmail(false);
            checkVal = [...checkVal,false];
        }
        if(message != ""){ checkVal = [...checkVal,true]; setValidMessage(true); }else{ checkVal = [...checkVal,false]; setValidMessage(false); }
           
        if(checkVal.includes(false)) { return false; } else { return true; }
    }
    
    const handleFundFormSubmit = async (e) => {
        setShowFlash(false);
        e.preventDefault();   
        if(!fundFormSubmitCheck())
        {
            setErrMsg("");
            return;
        }
        else{
            
            setErrMsg('');
            setValidName(true);
            setValidLastName(true);
            setIsValid(true);
            setValidEmail(true);
            setValidMessage(true);
            
        }
    
        try {
            setContactFormButton(false);
            var phoneValueGet = '';
            if((isValidPhoneNumber((phoneValue || ''))) == true)
            {
                const phoneNumber = parsePhoneNumber(phoneValue);
                if (phoneNumber) {
                    phoneValueGet = `${phoneNumber.number}`;
                }
            }
            const response = await axios.post(API_HOST+'/xhr/save-contact.php', { 
                name : `${name}`,
                last_name : `${lastName}`,
                phone : `${phoneValueGet}`,
                email : `${email}`,
                message : `${message}`,
                
            },{headers: {
                "Content-Type": "multipart/form-data",
                withCredentials: true
            }},)
            .then(response => {
                if(response.data.error == '0' || response.data.error == 0)
                {
                    setErrMsg('');
                    setName('');
                    setValidName(true);
                    setNameMsg('');
                    setLastName('');
                    setValidLastName(true);
                    setLastNameMsg('');
                    setEmail('');
                    setValidEmail(true);
                    setEmailMsg('');
                    setPhoneValue('');
                    setIsValid(true);
                    setPhoneMsg('');
                    setMessage('');
                    setValidMessage(true);
                    setMessageMsg('');
                    setContactFormButton(true);
                    //flash message
                    setFlashMsg(response.data.msg)
                    setShowFlash(true);
                    setFlashType('success');
                    // Toast.fire({
                    //   icon: 'success',
                    //   title: `${response.data.msg}`
                    // });
                    
                   
                    //$('#GetFundedModal').modal('hide');
                } else {
                    setFlashMsg('No Server Response')
                    setShowFlash(true);
                    setFlashType('error');
                }               
            })
            .catch(error => {
                if(!error?.response)
                {
                    // Toast.fire({
                    //     icon: 'error',
                    //     title: "No Server Response."
                    // });
                    //flash message
                    setFlashMsg('No Server Response')
                    setShowFlash(true);
                    setFlashType('error');
                }
                else if(error.response.data.error == 1)
                {
                    if(typeof error.response.data.data == 'object' && error.response.data.data !== null && Object.keys(error.response.data.data).length > 0)
                    {   
                        if('name' in error.response.data.data)
                        {
                            setNameMsg(error.response.data.data.name.toString());
                            setValidName(false);
                        }
    
                        if('lastName' in error.response.data.data)
                        {
                            setLastNameMsg(error.response.data.data.lastName.toString());
                            setValidLastName(false);
                        }
    
                        if('phone' in error.response.data.data)
                        {
                            setPhoneMsg(error.response.data.data.phone.toString());
                            setIsValid(false);
                        }
    
                        if('email' in error.response.data.data)
                        {
                            setEmailMsg(error.response.data.data.email.toString());
                            setValidEmail(false);
                        }

                        if('message' in error.response.data.data)
                        {
                            setMessageMsg(error.response.data.data.email.toString());
                            setValidMessage(false);
                        }
    
                    }  
                    // Toast.fire({
                    //     icon: 'error',
                    //     title: "contact us not send."
                    // }); 
                    setFlashMsg('contact us not send')
                    setFlashType('error');  
                    setShowFlash(true);
                                   
                }
                else if(error.response?.status === 409 )
                {
                    // Toast.fire({
                    //     icon: 'error',
                    //     title: "contact us not send."
                    // });
                    setFlashMsg('contact us not send')
                    setShowFlash(true);
                    setFlashType('error');    
                }
                else{
                    // Toast.fire({
                    //     icon: 'error',
                    //     title: "contact us not send."
                    // });
                    setFlashMsg('contact us not send')
                    setShowFlash(true);
                    setFlashType('error');    
                }
                setContactFormButton(true);
            });
            setContactFormButton(true);
        } catch (err) {
            setContactFormButton(true);
            console.error('Error fetching data catch:', err);
        }
    }
    

  const contactinfo = [
    {
        contactClassName: "col-md-12 col-sm-6",
        contactName: "Call Us",
        contactImg: phone,
        contactData: "+91 808 111 3111",
        contactLink: "tel:+918081113111",
    },
    {
        contactClassName: "col-md-12 col-sm-6",
        contactName: "Email Us",
        contactImg: at,
        contactData: "info@fundedfirm.com",
        contactLink: "mailto:info@fundedfirm.com",
    },
    {
        contactClassName: "col-md-12 col-sm-12",
        contactName: "Address",
        contactImg: location,
        contactData: "PM Communications, 6th Floor, Zydus Tower, Astron Tower, Iskcon Cross Rd, Satellite, Ahmedabad, Gujarat 380015",
        contactLink: "/",
    },
  ]

    

  return (
    <>
      <Helmet>
        <title>Contact Us | Funded Firm</title>
      </Helmet>

      <section className="relative">
        <div className="container-lg cl-custome">
          <div className="textlayout-main-bx">
            <div className="breadcrumb-bx">
                <Link className="breadcrumb-link" to="/">Home</Link>
                <div className="breadcrumb-link breadcrumb-active">/ Contact Us</div>
            </div>
            <div data-aos="fade-up" className="tlm-heading">Connect with Funded Firm: Unleash Your <br/> Trading Potential</div>
            <div data-aos="fade-up" data-aos-delay="400" className="tlm-sub-text">Embark on a journey to success with Funded Firm, where your questions and feedback become the compass guiding our commitment to excellence.</div>
          </div>
        </div>
      </section>

      <div className="py-2"></div>

      <section>
        <div className="container-lg cl-custome py-5">
            <div className="common-section">


                <div className="row justify-content-center">

                    <div className="col-lg-5 col-md-6 mt-3 mt-md-0 order-2 order-md-1">
                        <div className="row">
                            {contactinfo.map((item, index) => ( 
                                <div data-aos="fade-up" className={item.contactClassName} key={index}>
                                    <a className="contact-info" href={item.contactLink}>
                                        <span className="contact-icon">
                                            <img src={item.contactImg} alt="Email Icon" />
                                        </span>
                                        <div className="contact-text">
                                            <h6>{item.contactName}</h6>
                                            <p>{item.contactData}</p>
                                        </div>
                                    </a>
                                </div>
                            ))}

                            <div className="col-12 mt-3">
                                <div className="col-12" data-aos="fade-up">
                                    <Socialicon className="social-icon" />
                                </div>
                            </div>

                        </div>
                    </div>

                    <div className="col-lg-6 col-md-6 order-1 order-md-2">
                        <form className="row" onSubmit={handleFundFormSubmit}>

                            <div className="col-sm-6">
                               

                                <div className={`signinup-group ${name=='' && !validName ? "error-group" : ""} `}>
                                        <label className="group__label">First Name</label>
                                        <input type="text" ref={nameRef} placeholder="First Name" onChange={(e) => checkName(e.target.value)} aria-invalid={validName ? "false" : "true"} aria-describedby="name_error" />
                                        <span id="name_error" className={`form-error-msg mb-1 ${name=='' && !validName ? "" : "d-none"} `}>{nameMsg != '' ? nameMsg : 'Please enter firstname'}</span>
                                </div>
                            </div>

                            <div className="col-sm-6">
                                <div className={`signinup-group ${lastName=='' && !validLastName ? "error-group" : ""} `}>
                                    <label className="group__label">Last Name</label>
                                    <input type="text" placeholder="Last Name" ref={lastNameRef} onChange={(e) => checkLastName(e.target.value)} aria-invalid={validLastName ? "false" : "true"} aria-describedby="last_name_error"/>
                                    <span id="last_name_error" className={`form-error-msg mb-1 ${lastName=='' && !validLastName ? "" : "d-none"} `}>{lastNameMsg != '' ? lastNameMsg : 'Please enter lastname'}</span>
                                </div>
                            </div>


                            <div className="col-sm-6">
                                <div className={`signinup-group ${email=='' && !validEmail ? "error-group" : ""} `}>
                                    <label className="group__label">Email</label>
                                    <input type="email" id="email" ref={emailRef} autoComplete="off" onChange={(e) => checkEmail(e.target.value)} aria-invalid={validEmail ? "false" : "true"} aria-describedby="email_error" placeholder="Email ID" value={email} />
                                    <span id="email_error" className={`form-error-msg mb-1 ${!validEmail ? "" : "d-none"} `}>{emailMsg != '' ? emailMsg : 'Please enter valid email'}</span>
                                </div>
                            </div>

                            <div className="col-sm-6">
                                <div className={`signinup-group sg-country-code ${phoneValue=='' && !isValid ? "error-group" : ""} `}>
                                    <div className="group__label">Phone No.</div>
                                    <PhoneInput 
                                    // international 
                                    countryCallingCodeEditable={false} defaultCountry="IN"  placeholder="Phone Number" id="phone" ref={phoneRef} value={phoneValue} onChange={handleInputChange}/>
                                    {/* <input type="number" placeholder="Enter your Phone no. here"/>
                                    <span className="group_left_icon">+1</span> */}
                                    <span id="phone_error" className={`form-error-msg mb-1 ${!isValid ? "" : "d-none"} `}>{phoneMsg != '' ? phoneMsg : 'Please enter valid number'}</span>
                                </div>                            
                            </div>

                            <div className="col-12">
                                <div className={`signinup-group ${message=='' && !validMessage ? "error-group" : ""} `}>
                                    <label className="group__label">Message</label>
                                    <textarea id="message" ref={messageRef} autoComplete="off" onChange={(e) => checkMessage(e.target.value)} aria-invalid={validMessage ? "false" : "true"} aria-describedby="message_error" placeholder="Enter your message here.." ></textarea>
                                    <span id="message_error" className={`form-error-msg mb-1 ${!validMessage ? "" : "d-none"} `}>{messageMsg != '' ? messageMsg : 'Please enter message'}</span>
                                </div>
                            </div>
                        
                            <div className="col-12">
                            <button className={`widthfull common-btn-item cbi-fill mt-2 ${contactFormButton ? '' : 'd-none'}`} >Submit</button>
                                    <button type="button" className={`widthfull common-btn-item cbi-fill mt-2 lsb-loader-btn ${contactFormButton ? 'd-none' : ''} `}>
                                        <img className="invert" src={Img.loading} alt="Please wait" />Please wait ....
                                    </button>
                            </div>
                            <FlashMessage show={showFlash} message={flashMsg} type={flashType}></FlashMessage>
                        </form>
                    </div>

                </div>

            </div>
        </div>   
      </section>

      <div className="py-2"></div>


    </>
  );
};
